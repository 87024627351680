import * as React from "react";
import { Box } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const UserFaq = () => {
  return (
    <Box
    sx={{
      minHeight: "auto",
    }}
    >

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            Apple IDでログインできません。
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. Apple IDの設定をご確認ください。問題が解決しない場合は、Appleのサポートページをご利用ください。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            QRコードがスキャンできません。
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. カメラへのアクセス許可を確認してください。また、明るい場所でスキャンしてください。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            クーポンが利用できません。
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. クーポンが有効期限内であることをご確認ください。また、所有ポイントが必要ポイントに達しているかをご確認ください。
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </Box>
  );
};

export default UserFaq;