import * as React from "react";
import Feedback from "./Feedback";
import UserFaq from "./UserFaq";


function Support() {
  return (
    <>
      <header>
        <div className="h_inner">
          <div className="h_flex_item">
            <a href="/" target="_blank" rel="noopemer noreferrer">
              <img
                src={"img/logo.svg"}
                alt=""
                style={{ height: "1.25rem"}}
              />
            </a>
          </div>
        </div>
      </header>

      <main className="top">

        {/* よくある質問 */}
        <section id="faq" className="faq link_area">
          <div
            style={{ 
              display: "flex"
              , justifyContent: "center"
              , padding: "20px 0px"
            }}
          >
            お客様アプリ　サポートページ
          </div>
          <h2>よくある質問</h2>
            <UserFaq/>
        </section>

        {/* お問い合わせ */}
        <section id="ct" className="ct link_area">
          <div className="ct_inner">
            <h2>お問い合わせ</h2>
            <Feedback/>
          </div>
        </section>

      </main>
      
      <footer>
        <ul className="f_agreements2">
          <li><a href="./terms_of_service.html" target="_blank" rel="noopemer noreferrer">利用規約</a></li>
          <span className="f_separator"></span>
          <li><a href="./privacy_policy.html" target="_blank" rel="noopemer noreferrer">プライバシーポリシー</a></li>
        </ul>
        <p className="f_copyright">© 2024 conekuta</p>
      </footer>

    </>
  );
};

export default Support;
